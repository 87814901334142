import React, { FC, useEffect, useState } from 'react'

import { NoError } from '~/components/siteValidation/NoError'
import { SiteErrorsList } from '~/components/siteValidation/SiteErrorsList'
import { useSiteConfiguration } from '~/shared/contexts/SiteConfigurationContext'
import { SiteError } from "~/shared/models/siteErrors";
import { CheckingError } from "~/components/siteValidation/CheckingError";
import {
  apiConfiguration,
  apiOrderZone,
  getSites,
  getKnownWorker,
  getWorkers,
  getWorkshops,
  Status, getWorker,
} from '~/shared/apis/routes'
import { BOWorker } from "~/shared/models/models";
import { asyncMap } from "~/shared/utils/array";
import { formatHumanDate } from "~/shared/date";
import { useToaster } from "~/components/Toaster";

const SiteValidationScreen: FC = () => {
  const {
    siteId,
  } = useSiteConfiguration()
  const { showToast } = useToaster()

  const [errorsState, setErrorsState] = useState<SiteError[] | undefined>(undefined)

  useEffect(() => {
    const queryString = URLSearchParams ? Object.fromEntries(Array.from(new URLSearchParams(window.location.search).entries())) : {}
    const opts = queryString.api === 'true' ? {
      useDefaultWorkers: true,
      useDefaultWorkshops: true,
    } : {}
    checkSiteErrors(siteId, opts)
      .then((siteErrors) => setErrorsState(siteErrors))
      .catch((err) => {
        console.error('Error while validating site: ', err)
        showToast({
          intent: 'failure',
          message: "Impossible de valider la configuration du site",
        })
      })
  }, [siteId])

  return (
    <>
      <p></p>
      {errorsState === undefined && (
        <CheckingError />
      )}
      {errorsState && errorsState.length === 0 && (
        <NoError />
      )}
      {errorsState && errorsState.length > 0 && (
        <SiteErrorsList errors={errorsState} />
      )}
    </>
  )
}

type CheckSiteErrorsOpts = {
  useDefaultWorkers?: boolean | undefined
  useDefaultWorkshops?: boolean | undefined
}
async function checkSiteErrors(siteId: string, opts?: CheckSiteErrorsOpts): Promise<SiteError[]> {
  const useDefaultWorkers = opts?.useDefaultWorkers ?? false
  const useDefaultWorkshops = opts?.useDefaultWorkshops ?? false

  const errors: SiteError[] = []
  function addWarning(message: string) {
    // Hide warnings for now
    // errors.push({type: "warning", message})
  }
  function addError(message: string) {
    errors.push({type: "error", message})
  }

  const config = await apiConfiguration(siteId)
  const configWorkers = useDefaultWorkers ? config.workers : (await getWorkers(siteId)).workers
  const configWorkshops = useDefaultWorkshops ? config.workshops : (await getWorkshops(siteId)).workshops
  const site = await apiOrderZone(siteId)

  function mapById<A extends {id: string}>(els: readonly A[]): Map<string, A> {
    return new Map(els.map(el => [el.id, el]))
  }

  const designs = mapById(config.designs)
  const materials = mapById(config.materials)
  const workers = mapById(configWorkers)
  const workshops = mapById(configWorkshops)

  const assemblyOrders = mapById(site.assemblyOrders)
  const cuttingOrders = mapById(site.cuttingOrders)
  const sawhorses = mapById(site.sawhorses)

  const workerById: (workerId: string) => Promise<BOWorker | undefined> = (() => {
    const workersById = new Map<string, BOWorker | undefined>()
    async function fetchWorkerOrUndefined(workerId: string): Promise<BOWorker | undefined> {
      try {
        const knownWorker = await getKnownWorker(workerId)
        return {...knownWorker, deleted: false}
      } catch (e) {
        try {
          const worker = await getWorker(workerId)
          return {...worker, deleted: true}
        } catch (e) {
          return undefined
        }
      }
    }

    return async (workerId: string) => {
      if (workersById.has(workerId)) {
        return workersById.get(workerId)
      }
      const worker = await fetchWorkerOrUndefined(workerId)
      workersById.set(workerId, worker)
      return worker
    }
  })()

  const sitesById = mapById([
    ...await getSites(Status.initialized),
    ...await getSites(Status.unconfigured),
    ...await getSites(Status.configured),
  ])

  config.workers.forEach(worker => {
    if (!workshops.has(worker.associatedWorkshop.id)) {
      addWarning(`L'artisan ${worker.name} est lié à un atelier inconnu\u00A0: ${worker.associatedWorkshop.id}`)
    }
  })

  await asyncMap(async (ao) => {
    const designInfo = designs.get(ao.item.model.id)?.name
    const materialInfo = ao.item.material.id
    const creationDateInfo = formatHumanDate(ao.creationDate)
    const aoStatus =
      ao.status === 'RETRIEVED' ? `Récupérée` :
      ao.status === 'CANCELLED' ? `Annulée` :
      `En cours`
    const aoInfos = `${ao.id} (${designInfo ? `«\u00A0${designInfo}\u00A0» - ` : ''}${materialInfo} - ${creationDateInfo} - ${aoStatus})`

    if (ao.cuttingOrder.id && !cuttingOrders.has(ao.cuttingOrder.id)) {
      addError(`La commande ${aoInfos} est liée à une commande de coupe inconnue\u00A0: ${ao.cuttingOrder.id}`)
    }
    if (!designs.has(ao.item.model.id)) {
      addError(`La commande ${aoInfos} est liée à un modèle inconnu\u00A0: ${ao.item.model.id}`)
    }
    if (!materials.has(ao.item.material.id)) {
      addError(`La commande ${aoInfos} est liée à un matériaux inconnu\u00A0: ${ao.item.material.id}`)
    }
    if (ao.replacement && !designs.has(ao.replacement.model.id)) {
      addError(`La commande ${aoInfos} est liée à un modèle de remplacement inconnu\u00A0: ${ao.item.model.id}`)
    }
    if (ao.replacement && !materials.has(ao.replacement.material.id)) {
      addError(`La commande ${aoInfos} est liée à un matériaux de remplacement inconnu\u00A0: ${ao.replacement.material.id}`)
    }
    if (!workers.has(ao.workerReporter.id)) {
      const worker = await workerById(ao.workerReporter.id)
      if (!worker) {
        addError(`La commande ${aoInfos} est liée à un artisan inconnu\u00A0: ${ao.workerReporter.id}`)
      } else {
        const workerInfo = `${worker.id} (${worker.name}${worker.deleted ? ' - supprimé(e)' : ''})`
        const site = sitesById.get(worker.associatedSite.id)
        if (!site) {
          addError(`La commande ${aoInfos} est liée à un artisan d'un site inconnu\u00A0: ${workerInfo} (du site ${siteId})`)
        } else if (site.id !== siteId) {
          addError(`La commande ${aoInfos} est liée à un artisan d'un autre site\u00A0: ${workerInfo} (du site ${site.name})`)
        } else if (worker.deleted) {
          addError(`La commande ${aoInfos} est liée à un artisan supprimé\u00A0: ${worker.id} (${worker.name})`)
        } else {
          addError(`La commande ${aoInfos} est liée à un artisan inconnu\u00A0: ${workerInfo}`)
        }
      }
    }
    if (ao.workshopReporter && !workshops.has(ao.workshopReporter.id)) {
      addError(`La commande ${ao.id} est liée à un atelier inconnu\u00A0: ${ao.workshopReporter.id}`)
    }
  }, site.assemblyOrders)

  site.cuttingOrders.forEach(co => {
    if (co.sawhorse && !sawhorses.has(co.sawhorse.id)) {
      addWarning(`La commande de coupe ${co.id} (${co.status}) est liée à un chevalet inconnu\u00A0: ${co.sawhorse.id}`)
    }
    const missingAssemblyOrder = co.assemblyOrders.map(ao => ao.id).filter(aoId => !assemblyOrders.has(aoId))
    if (missingAssemblyOrder.length > 0) {
      addError(`La commande de coupe ${co.id} (${co.status}) est liée à des commandes de coupe inconnues\u00A0: ${missingAssemblyOrder}`)
    }
    if (co.assemblyOrders.length === 0) {
      addWarning(`La commande de coupe ${co.id} (${co.status}) n'est liée à aucune commande de table`)
    }
  })

  site.sawhorses.forEach(sh => {
    const missingCuttingOrders = sh.cuttingOrders.map(co => co.id).filter(coId => !cuttingOrders.has(coId))
    if (missingCuttingOrders.length > 0) {
      addError(`Le chevalet ${sh.id} est lié à des commandes de coupe inconnues\u00A0: ${missingCuttingOrders}`)
    }
    if (sh.cuttingOrders.length === 0) {
      addError(`Le chevalet ${sh.id} n'est lié à aucune commande de coupe`)
    }
  })

  return errors
}

export default SiteValidationScreen
